import React, { FC } from "react";
import { Todo } from "types/Todo";
import { User } from "types/User";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useAuth } from "contexts/jwt-provider";
import { ucfirst } from "utils/string-utils";
import { createTodo } from "services/todos.service";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";

interface UserTodosEditFormProps {
  todo?: Todo;
  user: User;
}

export const UserTodosEditForm: FC<UserTodosEditFormProps> = (props) => {
  const { todo, user, ...other } = props;
  const { getAccessTokenSilently } = useAuth();

  const formik = useFormik({
    initialValues: {
      name: todo?.name || "",
      notes: todo?.notes || "",
      due_date: todo?.due_date || Date.now(),
      submit: null,
    },
    validationSchema: Yup.object({
      name: Yup.string().max(255).required("Il nome è obbligatorio"),
      notes: Yup.string().max(255),
      due_date: Yup.date().min(new Date(), "La data deve essere futura"),
    }),
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (todo) {
          // update
          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
          toast.success("Compito aggiornato correttamente!");
        } else {
          await createTodo({
            accessToken,
            name: values.name,
            notes: values.notes,
            due_date: (values.due_date as string) ?? Date.now(),
            user_id: user.id,
          });
          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
          toast.success("Compito creato correttamente!");
        }
      } catch (err: any) {
        console.error(err);
        toast.error("Qualcosa è andato storto!");
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} {...other}>
      <Card>
        <CardHeader title={"Crea compito per l'utente " + ucfirst(user.name)} />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                error={Boolean(formik.touched.name && formik.errors.name)}
                fullWidth
                helperText={formik.touched.name && formik.errors.name}
                label="Nome"
                name="name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.name}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={Boolean(formik.touched.notes && formik.errors.notes)}
                fullWidth
                helperText={formik.touched.notes && formik.errors.notes}
                label="Note"
                name="notes"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.notes}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Data di consegna"
                  onChange={(value) => {
                    formik.setFieldValue("due_date", value as string);
                  }}
                  inputFormat="MM/DD/YYYY"
                  renderInput={(inputProps) => (
                    <TextField
                      error={Boolean(
                        formik.touched.due_date && formik.errors.due_date
                      )}
                      fullWidth
                      helperText={
                        formik.touched.due_date && formik.errors.due_date
                      }
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      {...inputProps}
                    />
                  )}
                  value={formik.values.due_date}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions
          sx={{
            flexWrap: "wrap",
            m: -1,
          }}
        >
          <Button
            disabled={formik.isSubmitting}
            type="submit"
            sx={{ m: 1 }}
            variant="contained"
          >
            {todo ? "Aggiorna" : "Crea"}
          </Button>
          <Link to={"/users/" + user.id}>
            <Button
              component="h2"
              disabled={formik.isSubmitting}
              sx={{
                m: 1,
                mr: "auto",
              }}
              variant="outlined"
            >
              Annulla
            </Button>
          </Link>
        </CardActions>
      </Card>
    </form>
  );
};
