import { useEffect, useState } from "react";
import { Todo } from "types/Todo";
import { getTodos } from "services/todos.service";
import { useAuth } from "contexts/jwt-provider";

type UseCalendarReturnType = {
  todos?: Todo[];
};

export const useCalendar = (): UseCalendarReturnType => {
  const [todos, setTodos] = useState<Todo[]>();

  const { getAccessTokenSilently } = useAuth();

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      const _todos = (await getTodos({ accessToken })).data as Todo[];

      setTodos(_todos);
    })();
  }, [getAccessTokenSilently, setTodos]);

  return {
    todos,
  };
};
