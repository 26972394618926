import React from "react";
import PDFViewer from "components/core/PDFViewer";

const pdfFileUrl = require('./../../assets/pdfs/TBM_CorsoSongwriting_PDF.pdf');

export const CorsoSongwriting: React.FC = () => {
  return (
    <div className="corso-songwriting">
      <PDFViewer pdfFileUrl={pdfFileUrl} />
    </div>
  );
}
