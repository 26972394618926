import React, { FC } from "react";
import {
  Card,
  CardActions,
  CardHeader,
  Divider,
  Theme,
  useMediaQuery,
} from "@mui/material";
import PropertyList, { PropertyListItem } from "components/core/PropertyList";

interface UserBasicDetailsProps {
  email: string;
  name?: string;
}

export const UserBasicDetails: FC<UserBasicDetailsProps> = (props) => {
  const { name, email, ...other } = props;

  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  const align = mdUp ? "horizontal" : "vertical";

  return (
    <Card {...other}>
      <CardHeader title="Dettagli dell'utente" />
      <Divider />
      <PropertyList>
        <PropertyListItem align={align} divider label="Nome" value={name} />
        <PropertyListItem align={align} divider label="Email" value={email} />
      </PropertyList>
    </Card>
  );
};
