import "@fullcalendar/common/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "@fullcalendar/list/main.css";
import "@fullcalendar/timeline/main.css";
import itLocale from "@fullcalendar/core/locales/it";
import React, { ReactNode, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import timelinePlugin from "@fullcalendar/timeline";
import type { Theme } from "@mui/material";
import { Box, useMediaQuery } from "@mui/material";
import { CalendarEventDialog } from "components/calendar/CalendarEventDialog";
import { CalendarToolbar } from "components/calendar/CalendarToolbar";
import useCalendarHandles from "./useCalendarHandles";
import { FullCalendarWrapper } from "./index.style";
import { gtm } from "lib/gtm";
import { useCalendar } from "hooks/calendar";

export const Calendar = () => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const { todos } = useCalendar();

  const {
    calendarRef,
    date,
    dialog,
    view,
    handleDateToday,
    handleViewChange,
    handleDatePrev,
    handleDateNext,
    handleAddClick,
    handleRangeSelect,
    handleEventSelect,
    handleEventResize,
    handleEventDrop,
    handleCloseDialog,
    handleResize,
  } = useCalendarHandles();

  useEffect(() => {
    gtm.push({ event: "page_view" });
  }, []);

  useEffect(() => {
    handleResize();
  }, [handleResize, smDown]);

  const selectedTodo =
    dialog.eventId &&
    todos?.find((todo) => "" + todo.id === "" + dialog.eventId);

  return (
    <>
      <Box
        component="main"
        sx={{
          backgroundColor: "white",
          flexGrow: 1,
          py: 8,
        }}
      >
        <CalendarToolbar
          date={date}
          onAddClick={handleAddClick}
          onDateNext={handleDateNext}
          onDatePrev={handleDatePrev}
          onDateToday={handleDateToday}
          onViewChange={handleViewChange}
          view={view}
          mobile={smDown}
        />
        <FullCalendarWrapper>
          <FullCalendar
            locale={itLocale}
            allDayMaintainDuration
            dayMaxEventRows={3}
            droppable
            editable
            eventClick={handleEventSelect}
            eventDisplay="block"
            eventDrop={handleEventDrop}
            eventResizableFromStart
            eventResize={handleEventResize}
            events={[]}
            headerToolbar={false}
            height={800}
            initialDate={date}
            initialView={view}
            plugins={[
              dayGridPlugin,
              interactionPlugin,
              listPlugin,
              timeGridPlugin,
              timelinePlugin,
            ]}
            ref={calendarRef}
            rerenderDelay={10}
            select={handleRangeSelect}
            selectable
            weekends
          />
        </FullCalendarWrapper>
      </Box>
      {/*event={selectedEvent === "" ? undefined : selectedEvent}*/}

      <CalendarEventDialog
        event={undefined}
        onAddComplete={handleCloseDialog}
        onClose={handleCloseDialog}
        onDeleteComplete={handleCloseDialog}
        onEditComplete={handleCloseDialog}
        open={dialog.isOpen}
        range={dialog.range}
      />
    </>
  );
};

export default Calendar;
