import { Box, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { OverallResults } from "components/dashboard/OverallResults";
import TomorrowSchedule from "../../components/dashboard/TomorrowSchedule/TomorrowSchedule";
import TodaySchedule from "../../components/dashboard/TodaySchedule/TodaySchedule";

export const Dashboard = (): React.ReactElement => (
  <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >
    <Container maxWidth="xl">
      <Box sx={{ mb: 4 }}>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography variant="h4">Progressi</Typography>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          <OverallResults />
        </Grid>
        <Grid item md={6} xs={12}>
          <TomorrowSchedule />
        </Grid>
        <Grid item md={12}>
          <TodaySchedule />
        </Grid>
      </Grid>
    </Container>
  </Box>
);
