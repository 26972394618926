import React from "react";
import { Route, Routes } from "react-router";
import { Login } from "pages/Login";
import { LoginGuard } from "components/layout/LoginGuard";
import { AppLayout } from "components/layout/AppLayout";
import { Dashboard } from "pages/Dashboard";
import { Chat } from "pages/Chat";
import { Calendar } from "pages/Calendar";
import { Users } from "pages/Users";
import { NewTodo } from "pages/NewTodo";
import { EditUsers } from "pages/EditUsers";
import { Profile } from "pages/Profile";
import { UserInfo } from "pages/UserInfo";
import { use } from "hooks/use";
import {
    CorsoSongwriting,
    DalSognoAllaRealta,
    DiventareManagerLeader,
    LeggeDiAttrazione, MindsetMusicale, ProtocolloBusinessArtist,
} from "../pages/Documentations";
import Documentations from "../pages/Documentations/Documentations";

export const AppRoutes = () => {
  const user = use.useUser;

  return (
    <Routes>
      <Route path={"/login"} element={<Login />} />
      <Route
        index
        element={
          <LoginGuard>
            <AppLayout>
              <Dashboard />
            </AppLayout>
          </LoginGuard>
        }
      />
      <Route
        path={"/chat"}
        element={
          <LoginGuard>
            <AppLayout>
              <Chat />
            </AppLayout>
          </LoginGuard>
        }
      />
      <Route
        path={"/profile"}
        element={
          <LoginGuard>
            <AppLayout>
              <Profile />
            </AppLayout>
          </LoginGuard>
        }
      />
      <Route
        path={"/calendar"}
        element={
          <LoginGuard>
            <AppLayout>
              <Calendar />
            </AppLayout>
          </LoginGuard>
        }
      />
      <Route
        path={"/documentation"}
        element={
          <LoginGuard>
            <AppLayout>
              <Documentations />
            </AppLayout>
          </LoginGuard>
        }
      /><Route
        path={"/documentation/corso-songwriting"}
        element={
          <LoginGuard>
            <AppLayout>
              <CorsoSongwriting />
            </AppLayout>
          </LoginGuard>
        }
      />
      <Route
        path={"/documentation/dal-sogno-alla-realta"}
        element={
          <LoginGuard>
            <AppLayout>
              <DalSognoAllaRealta />
            </AppLayout>
          </LoginGuard>
        }
      />
      <Route
        path={"/documentation/diventare-manager-leader"}
        element={
          <LoginGuard>
            <AppLayout>
              <DiventareManagerLeader />
            </AppLayout>
          </LoginGuard>
        }
      />
      <Route
        path={"/documentation/legge-di-attrazione"}
        element={
          <LoginGuard>
            <AppLayout>
              <LeggeDiAttrazione />
            </AppLayout>
          </LoginGuard>
        }
      />
      <Route
        path={"/documentation/mindset-musicale"}
        element={
          <LoginGuard>
            <AppLayout>
              <MindsetMusicale />
            </AppLayout>
          </LoginGuard>
        }
      />
      <Route
        path={"/documentation/protocollo-business-artist"}
        element={
          <LoginGuard>
            <AppLayout>
              <ProtocolloBusinessArtist />
            </AppLayout>
          </LoginGuard>
        }
      />
      {user?.is_admin && (
        <Route
          path={"/users"}
          element={
            <LoginGuard>
              <AppLayout>
                <Users />
              </AppLayout>
            </LoginGuard>
          }
        ></Route>
      )}
      {user?.is_admin && (
        <Route
          path={"/users/:id/todos/new"}
          element={
            <LoginGuard>
              <AppLayout>
                <NewTodo />
              </AppLayout>
            </LoginGuard>
          }
        ></Route>
      )}
      {user?.is_admin && (
        <Route
          path={"/todos/:todoId/edit"}
          element={
            <LoginGuard>
              <AppLayout>
                <NewTodo />
              </AppLayout>
            </LoginGuard>
          }
        ></Route>
      )}
      {user?.is_admin && (
        <Route
          path={"/users/:id/edit"}
          element={
            <LoginGuard>
              <AppLayout>
                <EditUsers />
              </AppLayout>
            </LoginGuard>
          }
        ></Route>
      )}
      {user?.is_admin && (
        <Route
          path={"/users/:id"}
          element={
            <LoginGuard>
              <AppLayout>
                <UserInfo />
              </AppLayout>
            </LoginGuard>
          }
        ></Route>
      )}
      <Route
        path={"*"}
        element={
          <LoginGuard>
            <AppLayout>
              <Dashboard />
            </AppLayout>
          </LoginGuard>
        }
      />
    </Routes>
  );
};
