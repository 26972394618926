import { useMemo } from "react";
import { getAdminSections, getSections } from "./sections";
import { useUser } from "../../user";

export const useSections = () => {
  const user = useUser();

  return useMemo(() => {
    let result = getSections();

    if (user?.is_admin) {
      result = [
        ...result,
        ...getAdminSections(),
      ];
    }

    return result;
  }, [user]);
};
