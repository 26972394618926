import type { FC } from "react";
import { useMemo } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { addMinutes } from "date-fns";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Dialog,
  Divider,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Trash as TrashIcon } from "icons/trash";
import type { CalendarEvent } from "types/Calendar";

interface CalendarEventFormProps {
  event?: CalendarEvent;
  onAddComplete?: () => void;
  onClose?: () => void;
  onDeleteComplete?: () => void;
  onEditComplete?: () => void;
  open?: boolean;
  range?: { start: number; end: number };
}

interface FormValues {
  allDay: boolean;
  color: string;
  description: string;
  end: Date;
  start: Date;
  title: string;
  submit: string | null;
}

export const CalendarEventDialog: FC<CalendarEventFormProps> = (props) => {
  const {
    event,
    onAddComplete,
    onClose,
    onDeleteComplete,
    onEditComplete,
    open,
    range,
  } = props;
  const initialValues = useMemo((): FormValues => {
    if (event) {
      return {
        allDay: event.allDay || false,
        color: event.color || "",
        description: event.description || "",
        end: event.end ? new Date(event.end) : addMinutes(new Date(), 30),
        start: event.start ? new Date(event.start) : new Date(),
        title: event.title || "",
        submit: null,
      };
    }

    if (range) {
      return {
        allDay: false,
        color: "",
        description: "",
        end: new Date(range.end),
        start: new Date(range.start),
        title: "",
        submit: null,
      };
    }

    return {
      allDay: false,
      color: "",
      description: "",
      end: addMinutes(new Date(), 30),
      start: new Date(),
      title: "",
      submit: null,
    };
  }, [event, range]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      allDay: Yup.bool(),
      description: Yup.string().max(5000),
      end: Yup.date(),
      start: Yup.date(),
      title: Yup.string().max(255).required("Title is required"),
    }),
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        const data = {
          allDay: values.allDay,
          description: values.description,
          end: values.end.getTime(),
          start: values.start.getTime(),
          title: values.title,
        };

        /*
        if (event) {
          await dispatch(
            updateEvent({
              eventId: event.id!,
              update: data,
            })
          );
          toast.success("Event updated!");
        } else {
          await dispatch(createEvent(data));
          toast.success("Event added!");
        }
         */

        if (!event && onAddComplete) {
          onAddComplete();
        }

        if (event && onEditComplete) {
          onEditComplete();
        }
      } catch (err: any) {
        console.error(err);
        toast.error("Something went wrong!");
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const handleStartDateChange = (date: Date | null): void => {
    formik.setFieldValue("start", date);

    // Prevent end date to be before start date
    if (formik.values.end && date && date > formik.values.end) {
      formik.setFieldValue("end", date);
    }
  };

  const handleEndDateChange = (date: Date | null): void => {
    formik.setFieldValue("end", date);

    // Prevent start date to be after end date
    if (formik.values.start && date && date < formik.values.start) {
      formik.setFieldValue("start", date);
    }
  };

  const handleDelete = async (): Promise<void> => {
    try {
      if (!event) {
        return;
      }
      /*
      await dispatch(
        deleteEvent({
          eventId: event.id!,
        })
      );
 */
      onDeleteComplete?.();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} open={!!open}>
      <form onSubmit={formik.handleSubmit} style={{backgroundColor: "white"}}>
        <Box sx={{ p: 3 }}>
          <Typography align="center" gutterBottom variant="h5">
            {event ? "Modifica Compito" : "Aggiungi Compito"}
          </Typography>
        </Box>
        <Box sx={{ p: 3 }}>
          <TextField
            error={Boolean(formik.touched.title && formik.errors.title)}
            fullWidth
            helperText={formik.touched.title && formik.errors.title}
            label="Titolo"
            name="title"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.title}
          />
          <Box sx={{ mt: 2 }}>
            <TextField
              error={Boolean(
                formik.touched.description && formik.errors.description
              )}
              fullWidth
              helperText={
                formik.touched.description && formik.errors.description
              }
              label="Descrizione"
              name="description"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.description}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.allDay}
                  name="allDay"
                  onChange={formik.handleChange}
                />
              }
              label="Tutto il giorno"
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <DateTimePicker
              label="Start date"
              onChange={handleStartDateChange}
              renderInput={(inputProps) => (
                <TextField fullWidth {...inputProps} />
              )}
              value={formik.values.start}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <DateTimePicker
              label="End date"
              onChange={handleEndDateChange}
              renderInput={(inputProps) => (
                <TextField fullWidth {...inputProps} />
              )}
              value={formik.values.end}
            />
          </Box>
          {Boolean(formik.touched.end && formik.errors.end) && (
            <Box sx={{ mt: 2 }}>
              <FormHelperText error>
                {formik.errors.end as string}
              </FormHelperText>
            </Box>
          )}
        </Box>
        <Divider />
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            p: 2,
          }}
        >
          {event && (
            <IconButton onClick={(): Promise<void> => handleDelete()}>
              <TrashIcon fontSize="small" />
            </IconButton>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Button onClick={onClose}>Cancel</Button>
          <Button
            disabled={formik.isSubmitting}
            sx={{ ml: 1 }}
            type="submit"
            variant="contained"
          >
            Confirm
          </Button>
        </Box>
      </form>
    </Dialog>
  );
};

CalendarEventDialog.propTypes = {
  // @ts-ignore
  event: PropTypes.object,
  onAddComplete: PropTypes.func,
  onClose: PropTypes.func,
  onDeleteComplete: PropTypes.func,
  onEditComplete: PropTypes.func,
  open: PropTypes.bool,
  // @ts-ignore
  range: PropTypes.object,
};
