export const useChatComposer = () => {
  return {
    createThread: async (
      recipients: any[],
      message: string
    ): Promise<string> => {
      return "";
    },
  };
};
