import React, { FC } from "react";
import { Todo } from "types/Todo";
import { User } from "types/User";
import {
  Card,
  CardHeader,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { ArrowRight as ArrowRightIcon } from "icons/arrow-right";
import {
  Scrollbar,
  MoreMenu,
  SeverityPill,
  MoreMenuAction,
} from "components/core";
import { Plus as PlusIcon } from "icons/plus";
import { Pencil } from "../../../icons/pencil";

interface UserTodosProps {
  todos: Todo[];
  user: User;
}

export const UserTodos: FC<UserTodosProps> = (props) => {
  const { todos, user } = props;
  const navigate = useNavigate();

  const actions: MoreMenuAction[] = [
    {
      label: "Aggiungi nuovo",
      Icon: <PlusIcon fontSize={"small"} sx={{ color: "black" }} />,
      onClick: () => {
        void navigate("/users/" + user.id + "/todos/new");
      },
    },
  ];

  return (
    <Card {...props}>
      <CardHeader
        action={<MoreMenu actions={actions} />}
        title="Lista compiti"
      />
      <Divider />
      <Scrollbar>
        <Table sx={{ minWidth: 600 }}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Data</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Note</TableCell>
              <TableCell>Stato</TableCell>
              <TableCell align="right">Azioni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {todos.map((todo) => (
              <TableRow key={todo.id}>
                <TableCell>#{todo.id}</TableCell>
                <TableCell>{todo.due_date}</TableCell>
                <TableCell>{todo.name}</TableCell>
                <TableCell>{todo.notes}</TableCell>
                <TableCell>
                  <SeverityPill color={todo.completed ? "success" : "error"}>
                    {todo.completed ? "Completato" : "Non completato"}
                  </SeverityPill>
                </TableCell>
                <TableCell align="right">
                  <Link to={"/todos/" + todo.id + "/edit"}>
                    <IconButton component="h2">
                      <Pencil fontSize="small" />
                    </IconButton>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      <TablePagination
        component="div"
        count={todos.length}
        onPageChange={(): void => {}}
        onRowsPerPageChange={(): void => {}}
        page={0}
        rowsPerPage={5}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};
