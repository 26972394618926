import React from "react";
import {
  Container,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";

interface DocumentationItem {
  title: string;
  path: string;
}

const documentations: DocumentationItem[] = [
  { title: "Corso Songwriting", path: "/documentation/corso-songwriting" },
  {
    title: "Dal Sogno Alla Realtà",
    path: "/documentation/dal-sogno-alla-realta",
  },
  {
    title: "Diventare Manager Leader",
    path: "/documentation/diventare-manager-leader",
  },
  { title: "Legge Di Attrazione", path: "/documentation/legge-di-attrazione" },
  { title: "Mindset Musicale", path: "/documentation/mindset-musicale" },
  {
    title: "Protocollo Business Artist",
    path: "/documentation/protocollo-business-artist",
  },
];

const Documentation: React.FC = () => {
  return (
    <Container>
      <Box mt={4}>
        <Typography variant="h1">Documentazione</Typography>
      </Box>
      <Box mt={4}>
        <List>
          {documentations.map((doc, index) => (
            <ListItem key={index} component={Link} to={doc.path}>
              <ListItemText primary={doc.title} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Container>
  );
};

export default Documentation;
