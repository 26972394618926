import React, {
  MouseEvent,
  FormEvent,
  useRef,
  useState,
  useCallback,
  ChangeEvent,
  useEffect,
} from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import { Search as SearchIcon } from "icons/search";
import { UsersListTable } from "components/users";
import { User } from "types/User";
import { createUser, getAllUsers } from "services/user.service";
import { useAuth } from "contexts/jwt-provider";

interface Filters {
  query?: string;
}

const applyFilters = (users: User[], filters: Filters): User[] =>
  users.filter((user) => {
    if (filters.query) {
      let queryMatched = false;
      const properties: ("email" | "name")[] = ["email", "name"];

      properties.forEach((property) => {
        if (
          user[property].toLowerCase().includes(filters.query!.toLowerCase())
        ) {
          queryMatched = true;
        }
      });

      if (!queryMatched) {
        return false;
      }
    }

    return true;
  });

const applyPagination = (
  users: User[],
  page: number,
  rowsPerPage: number
): User[] => users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

export const Users = (): React.ReactElement => {
  const [users, setUsers] = useState<User[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [filters, setFilters] = useState<Filters>({
    query: "",
  });
  const [openModal, setOpenModal] = useState(false);
  const [newUser, setNewUser] = useState({
    email: "",
    fullName: "",
    password: "",
  });

  const { getAccessTokenSilently } = useAuth();

  const queryRef = useRef<HTMLInputElement | null>(null);
  const filteredUsers = applyFilters(users, filters);
  const paginatedUsers = applyPagination(filteredUsers, page, rowsPerPage);

  const getUsers = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const data = await getAllUsers({ accessToken });

      setUsers(data.data as User[]);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(
    () => {
      void getUsers();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleNewUserChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleCreateNewUser = async (event: FormEvent) => {
    event.preventDefault();

    try {
      // Retrieve the access token using the getAccessTokenSilently function from the useAuth hook
      const accessToken = await getAccessTokenSilently();

      // Call the createUser function and pass in the required data
      const response = await createUser({
        accessToken,
        email: newUser.email,
        fullName: newUser.fullName,
        password: newUser.password,
      });

      if (response.error) {
        console.error("Error creating user:", response.error);
        // Handle error (e.g., show a message to the user)
      } else {
        console.log("User created successfully:", response.data);
        // Handle success (e.g., update the UI, navigate to another page)
        void getUsers(); // Refresh the users list
        handleCloseModal(); // Close the modal
      }
    } catch (err) {
      console.error("An unexpected error occurred:", err);
      // Handle unexpected errors
    }
  };

  const handleQueryChange = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setFilters((prevState) => ({
      ...prevState,
      query: queryRef.current?.value,
    }));
  };

  const handleRowsPerPageChange = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handlePageChange = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    setPage(newPage);
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="xl">
        <Box sx={{ mb: 4 }}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography variant="h4">Utenti</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
              >
                Nuovo utente
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Card>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexWrap: "wrap",
              m: -1.5,
              p: 3,
            }}
          >
            <Box
              component="form"
              onSubmit={handleQueryChange}
              sx={{
                flexGrow: 1,
                m: 1.5,
              }}
            >
              <TextField
                defaultValue=""
                fullWidth
                inputProps={{ ref: queryRef }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                placeholder="Cerca tra gli utenti poi premi invio"
              />
            </Box>
          </Box>
          <UsersListTable
            users={paginatedUsers}
            usersCount={filteredUsers.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPage={rowsPerPage}
            page={page}
          />
        </Card>
      </Container>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: 24,
            p: 4,
          }}
        >
          <form onSubmit={handleCreateNewUser}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              onChange={handleNewUserChange}
              required
              type="email"
              value={newUser.email}
              sx={{ marginBottom: 2 }} // Add vertical space
            />
            <TextField
              fullWidth
              label="Nome completo"
              name="fullName"
              onChange={handleNewUserChange}
              required
              type="text"
              value={newUser.fullName}
              sx={{ marginBottom: 2 }} // Add vertical space
            />
            <TextField
              fullWidth
              label="Password"
              name="password"
              onChange={handleNewUserChange}
              required
              type="password"
              value={newUser.password}
              sx={{ marginBottom: 2 }} // Add vertical space
            />
            <Button type="submit" variant="contained" color="primary">
              Crea
            </Button>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default Users;
