import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useAuth } from "contexts/jwt-provider";
import { Avatar, Box, Chip, Link as MUILink, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getInitials } from "utils/get-initials";
import { getUser as getUserService } from "../../services/user.service";
import { User } from "../../types/User";
import { ucfirst } from "../../utils/string-utils";
import { UserTodosEditForm } from "../../components/users";
import { Todo } from "../../types/Todo";
import {getTodo as getTodoService} from "../../services/todos.service";

export const NewTodo = (): React.ReactElement => {
  const { id, todoId } = useParams();
  const [user, setUser] = useState<User>();
  const [todo, setTodo] = useState<Todo>();

  const { getAccessTokenSilently } = useAuth();

  const getUser = useCallback(async () => {
    if (id === undefined) {
      return;
    }

    try {
      const accessToken = await getAccessTokenSilently();
      const data = await getUserService({ accessToken, id: parseInt(id) });

      setUser(data.data as User);
    } catch (err) {
      console.error(err);
    }
  }, [id]);

  const getTodo = useCallback(async () => {
    if (todoId === undefined) {
      return;
    }

    try {
      const accessToken = await getAccessTokenSilently();
      const data = await getTodoService({ accessToken, id: parseInt(todoId) });

      setTodo({
        id: data.data.id,
        name: data.data.name,
        notes: data.data.notes,
        due_date: data.data.due_date,
        completed: data.data.completed,
      });
    } catch (err) {
      console.error(err);
    }
  }, [todoId]);

  useEffect(() => {
    void getUser();
    void getTodo();
  }, []);

  if (!user) {
    return <></>;
  }

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "background.default",
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="md">
        <Box sx={{ mb: 4 }}>
          <Link to={"/users/" + id}>
            <MUILink
              color="textPrimary"
              component="h2"
              sx={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <ArrowBackIcon fontSize="small" sx={{ mr: 1 }} />
              <Typography variant="subtitle2">{ucfirst(user.name)}</Typography>
            </MUILink>
          </Link>
        </Box>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            overflow: "hidden",
          }}
        >
          <div>
            <Typography noWrap variant="h4">
              Crea un nuovo compito
            </Typography>
          </div>
        </Box>
        <Box mt={3}>
          <UserTodosEditForm user={user} todo={todo} />
        </Box>
      </Container>
    </Box>
  );
};
