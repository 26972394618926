import type { FC } from "react";
import PropTypes from "prop-types";
import type { Locale } from "date-fns";
import { formatDistanceStrict } from "date-fns";
import locale from "date-fns/locale/en-US";
import {
  Avatar,
  AvatarGroup,
  Box,
  ListItem,
  ListItemAvatar,
  Typography,
} from "@mui/material";
import type { Thread } from "types/Chat";
import { useUser } from "../../../hooks/user";

const formatDistanceLocale: Record<string, string> = {
  lessThanXSeconds: "{{count}}s",
  xSeconds: "{{count}}s",
  halfAMinute: "30s",
  lessThanXMinutes: "{{count}}m",
  xMinutes: "{{count}}m",
  aboutXHours: "{{count}}h",
  xHours: "{{count}}h",
  xDays: "{{count}}d",
  aboutXWeeks: "{{count}}w",
  xWeeks: "{{count}}w",
  aboutXMonths: "{{count}}m",
  xMonths: "{{count}}m",
  aboutXYears: "{{count}}y",
  xYears: "{{count}}y",
  overXYears: "{{count}}y",
  almostXYears: "{{count}}y",
};

const customLocale: Locale = {
  ...locale,
  formatDistance: (token, count, options) => {
    options = options || {};

    const result = formatDistanceLocale[token].replace("{{count}}", count);

    if (options.addSuffix) {
      if (options.comparison > 0) {
        return "in " + result;
      } else {
        return result + " ago";
      }
    }

    return result;
  },
};

interface ChatThreadItemProps {
  active?: boolean;
  onSelect?: () => void;
  thread: Thread;
}

export const ChatThreadItem: FC<ChatThreadItemProps> = (props) => {
  const { active, thread, onSelect, ...other } = props;
  // To get the user from the authContext, you can use
  // `const { user } = useAuth();`
  const user = useUser();

  const recipients = thread.participants!.filter(
    (participant) => participant.id !== "" + user?.id
  );
  const lastMessage = thread.messages[thread.messages.length - 1];
  const name = recipients
    .reduce(
      (names: string[], participant) => [
        ...names,
        participant.name ?? participant.email,
      ],
      []
    )
    .join(", ");
  let content = "";

  if (lastMessage) {
    const author = lastMessage.author_id === "" + user?.id ? "Me: " : "";
    const message =
      lastMessage.content_type === "image" ? "Sent a photo" : lastMessage.body;

    content = `${author}${message}`;
  }

  return (
    <ListItem
      disableGutters
      disablePadding
      divider
      onClick={onSelect}
      sx={{
        ...(active && {
          backgroundColor: "primary.main",
        }),
        cursor: "pointer",
        overflow: "hidden",
        px: 2,
        py: 3,
      }}
      {...other}
    >
      <ListItemAvatar
        sx={{
          display: "flex",
          justifyContent: {
            sm: "flex-start",
            xs: "center",
          },
        }}
      >
        <AvatarGroup
          max={2}
          sx={{
            "& .MuiAvatar-root":
              recipients.length > 1
                ? {
                    height: 26,
                    width: 26,
                    "&:nth-of-type(2)": {
                      mt: "10px",
                    },
                  }
                : {
                    height: 36,
                    width: 36,
                  },
          }}
        >
          {recipients.map((recipient) => (
            <Avatar key={recipient.id} src={recipient.avatar || undefined} />
          ))}
        </AvatarGroup>
      </ListItemAvatar>
      <Box
        sx={{
          flexGrow: 1,
          mr: 2,
          overflow: "hidden",
        }}
      >
        <Typography noWrap variant="subtitle2">
          {name}
        </Typography>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
          }}
        >
          {Boolean(thread.unreadCount && thread.unreadCount > 0) && (
            <Box
              sx={{
                backgroundColor: "primary.main",
                borderRadius: "50%",
                height: 8,
                mr: 1,
                width: 8,
              }}
            />
          )}
          <Typography
              color={active ? "white" : "textSecondary"}
            noWrap
            sx={{ flexGrow: 1 }}
            variant="subtitle2"
          >
            {content}
          </Typography>
        </Box>
      </Box>
      {lastMessage ? (
        <Typography
          color={active ? "white" : "textSecondary"}
          sx={{ whiteSpace: "nowrap" }}
          variant="caption"
        >
          {formatDistanceStrict(new Date(lastMessage.created_at), new Date(), {
            addSuffix: false,
            locale: customLocale,
          })}
        </Typography>
      ) : (
        <></>
      )}
    </ListItem>
  );
};

ChatThreadItem.propTypes = {
  active: PropTypes.bool,
  onSelect: PropTypes.func,
  // @ts-ignore
  thread: PropTypes.object.isRequired,
};

ChatThreadItem.defaultProps = {
  active: false,
};
